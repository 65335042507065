<template>
  <div class="member-layout pb-0">
  
    <div class="complete-msg">
      <img src="@/assets/svg/joinComplete_dog.svg">
      <h1><span class="color-1">꼬랩</span> 가족이 되었어요. 잘 지내봐요!</h1>
      <router-link to="/">홈 바로가기</router-link>
    </div>
    <div class="mypet-regist-btn" @click.prevent="mypet">
        <img src="@/assets/svg/joinComplete_cat.svg">
        <h3>반려동물 등록하기</h3>
        <div class="flex-between-center">
          <p>반려동물 등록하고<br>나에게 딱 맞는 콘텐츠를 받아보세요!</p>
          <span>등록하기<i></i></span>
        </div>
    </div>

  </div>
</template>
<script>
import snsMixin from "@/mixins/snsMixin"
export default {
  name: "join",
  mixins: [snsMixin],
  data() {
    return {
    }
  },
  methods:{
    mypet(){
      this.$router.push('/mypet/register')
    },
  }
}
</script>
