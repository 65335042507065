import {mapActions} from "vuex"
export default {
  data() {
    return {
      state: this.$route.fullPath,
      snsToken: null,
    }
  },
  methods: {
    ...mapActions({
      setMsgPopup: 'common/setMsgPopup',
    }),
    async kakaoSync(code, state) {
      const { result, data } = await this.$api.loginKakaoSync({ code: code, state: state })
      switch (result) {
        case 'success':
          const info = this.$parseJwt(data.token)
          if (info) {
            localStorage.setItem('auth', data.token)
            localStorage.setItem('id', info.id)
            localStorage.setItem('email', info.email)
            localStorage.setItem('name', info.name)
            localStorage.setItem('url', info.url ? info.url : '')
            localStorage.setItem('target', info.target)
            localStorage.setItem('phone', info.phone)
          }
          this.goPage()
          break;
        case 'new-member':
          if (data.input.length > 0) {
            for (let src of data.input) {
              let script = document.createElement('script')
              script.setAttribute('src', src)
              document.head.appendChild(script)
            }
          }
          setTimeout(() => {
            if (data.script.length > 0) {
              let script = document.createElement('script')
              script.innerHTML = data.script
              document.head.appendChild(script)
            }
            const token = data.token
            const info = this.$parseJwt(token)
            if (info) {
              localStorage.setItem('auth', token)
              localStorage.setItem('id', info.id)
              localStorage.setItem('email', info.email)
              localStorage.setItem('name', info.name)
              localStorage.setItem('url', info.url ? info.url : '')
              localStorage.setItem('target', info.target)
              localStorage.setItem('phone', info.phone)
            }
            location.href = "/join/complete"
          }, 300)
          break;
        case 'leave-member':
          this.alertPopup(this.$msg('rejoin').replace('{{date}}', data.resignDate))
          break;
        case 'member':
          localStorage.setItem('kakaosync-connect-data', JSON.stringify({
            memberList: data.memberList,
            snsId: data.sns_id,
          }))
          await this.$router.push('/join/exist')
          break;
        case 'sleep':
          localStorage.setItem('sleep', data.token)
          location.href = "/sleep"
          break;
        case 'email-no-input':
          this.alertPopup(this.$msg('kakaosync-no-email'))
          break;
        case 'fail':
          this.alertPopup(this.$msg('fail'))
          break;
        default:
      }
    },
    goPage() {
      let page = '/'
      if (localStorage.getItem('loginBeforePage')) {
        page = localStorage.getItem('loginBeforePage')
        localStorage.removeItem('loginBeforePage')
      }
      location.href = page
    },
    alertPopup(msg) {
      this.setMsgPopup({
        type: 'alert',
        message: msg,
        okay: 'MSG',
        okayCallback: () => {
          this.setMsgPopup()
        }
      })
    },
  }
}
